<template lang="pug">
  v-container
    base-navigation-tabs(
      :items="links"
    )
    v-card(
      min-height="80vh"
      tile
      elevation="0"
    )
      router-view
      //- forecasts
</template>
<script>
import searchDelay from '@/libs/searchDelay.extra'

const pushDelay = searchDelay(100)
const links = [
  {
    label: 'Customers',
    path: { name: 'customers.table' },
  },
  {
    label: 'New Customer',
    path: { name: 'customers.create' },
  },
  {
    label: 'Customer Category',
    path: { name: 'customers.categories' },
  },
  {
    label: 'Product Category',
    path: { name: 'products.categories' },
  },
  {
    label: 'Applications',
    path: { name: 'customers.applications' },
  },
]
export default {
  name: 'Customers',
  components: {
    // forecasts: () => import('./forecasts/Show'),
  },
  data () {
    return {
      links,
    }
  },
  methods: {
    routePush ({ name }) {
      if (name === this.$route.name) return
      pushDelay(() => {
        this.$router.push({ name })
      })
    },
  },
}
</script>
